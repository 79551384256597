import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Teacher } from '../_models/teacher';
import { AuthenticationService } from '../_services/authentication.service';


@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.css']
})
export class TeacherComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  currentTeacher: Teacher;
  constructor(router: Router,
    private authenticationService: AuthenticationService) {
    this.authenticationService.currentTeacher.subscribe(x => this.currentTeacher = x);
    this.sub = router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => setTimeout(() => {
      window.scrollTo(0, 0);
    }));
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
