import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-teacher-login',
  templateUrl: './teacher-login.component.html',
  styleUrls: ['./teacher-login.component.css']
})
export class TeacherLoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  resendLink = false;
  returnUrl: string;
  error = '';
  success: any;
  timezoneIdentifier = null;
  timezoneAbbreviation = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneAbbreviation = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;
    this.error = null;
    this.resendLink = false;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.teacherLogin(this.f.email.value, this.f.password.value, this.timezoneIdentifier, this.timezoneAbbreviation)
      .pipe(first())
      .subscribe(
        data => {
          localStorage.setItem('student_count', data.teacher.student_count || 0);
          if (data.teacher.student_count > 0)
            this.router.navigate(['student-list']);
          else
            this.router.navigate(['educator']);
        },
        error => {
          if (error === "Email not verified. Kindly check your email to verify.") {
            this.resendLink = true;
          }
          this.error = error;
          this.loading = false;
        });
  }

  resendVerification() {
    this.loading = true;
    this.error = null;
    this.success = null;
    this.sharedService.create('teacher/resend-verify-email', { email: this.f.email.value })
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          this.success = data;
          setTimeout(() => this.success = null, 6000);
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
