import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit, AfterViewInit {
  public isRendered = false;
  contactUsForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  success: boolean = false;
  kiddictionToggle: boolean = false;
  slides = [
    {
      title: 'Sun Takes a Vacation',
      subtitle: 'Sun Takes a Vacation',
      image: 'https://trprod.s3.us-east-1.amazonaws.com/story_props/STAV.png',
      link: 'https://trprod.s3.us-east-1.amazonaws.com/demo/Sun_takes_a_vacation.mp3',
    },
    {
      title: 'Ramayana',
      subtitle: 'The Legends of Lord Rama',
      image: 'https://trprod.s3.us-east-1.amazonaws.com/story_props/Ramayana.png',
      link: 'https://trprod.s3.us-east-1.amazonaws.com/demo/Ramayana.mp3',
    },
    {
      title: 'Coming soon',
      image: '../assets/final-images/comingsoon-slide.png',
    }
  ];
  testimonialSlides = [
    {
      name: 'Ronne Galle',
      title: 'Project Manager',
      image: 'https://images.unsplash.com/photo-1572561300743-2dd367ed0c9a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=300',
      feedback: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat',
    },
    {
      name: 'Missy Limana',
      title: 'Engineer',
      image: 'https://images.unsplash.com/photo-1588361035994-295e21daa761?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=301&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=301',
      feedback: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat',
    },
    {
      name: 'Martha Brown',
      title: 'Project Manager',
      image: 'https://images.unsplash.com/photo-1572561300743-2dd367ed0c9a?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=300&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=300',
      feedback: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat',
    },
    {
      name: 'Hanna Lisem',
      title: 'Project Manager',
      image: 'https://images.unsplash.com/photo-1549836938-d278c5d46d20?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=303&ixid=eyJhcHBfaWQiOjF9&ixlib=rb-1.2.1&q=50&w=303',
      feedback: 'Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat',
    },

  ];
  slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, centerPadding: '100px', centerMode: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding:'10px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding:'20px'
      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
};
  slideConfigTestimonial = {
    "slidesToShow": 3, "slidesToScroll": 3, centerPadding: '50px', centerMode: true, autoplay: true, autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10px',
          autoplay: true
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };
  public slideIndex = 0;
  @ViewChild('player', { static: false }) private player: ElementRef<HTMLAudioElement>;
  @ViewChild('video', { static: false }) private video: ElementRef<HTMLVideoElement>;
  public isPlaying = false;
  public recreateAudio = true;
  public max = 0;
  public currentTime = 0;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.contactUsForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isRendered = true;
      setTimeout(() => {
        this.video.nativeElement.muted = true;
        this.video.nativeElement.play();
      });
    });
  }

  public onPlaying() {
    this.isPlaying = true;
  }

  public onPaused() {
    this.isPlaying = false;
  }

  public togglePlay() {
    if (this.isPlaying) {
      this.player.nativeElement.pause();
    } else {
      this.player.nativeElement.play();
    }
    this.isPlaying = !this.isPlaying;
  }

  get f() { return this.contactUsForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactUsForm.invalid) {
      return;
    }
    this.loading = true;
    this.sharedService.create('contact-us', this.contactUsForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.success = true;
          this.loading = false;
          setTimeout(() => {
            this.success = false;
            this.contactUsForm.reset();
            this.submitted = false;
          }, 7000);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  addSlide() {

  }

  removeSlide() {

  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log(e);
  }

  afterChange(e) {
    console.log(e);
    this.slideIndex = e.currentSlide;
    this.recreateAudio = true;
    setTimeout(() => {
      this.togglePlay();
    });
  }

  beforeChange(e) {
    console.log(e);
    this.recreateAudio = false;
    this.isPlaying = false;
  }

  public setDuration() {
    this.max = this.player.nativeElement.duration;
  }

  public updateTime() {
    this.currentTime = this.player.nativeElement.currentTime;
  }

  public onSeeked(e) {
    this.player.nativeElement.currentTime = e.target.value;
  }
}
