import { Address } from "../_models/address";

export class GooglePlaceHelper {
  static getAddress(place: any): Address {
    let address = new Address();
    let addressComponents = place.address_components;

    let addressComponent = null;

    //set address line 1
    address.addressLine1 = '';

    //Fetch Name of the Entity.
    let name = place.name;
    if (name && name.length > 0) {
      address.addressLine1 += name;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'room');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'floor');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'subpremise');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'premise');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'street_number');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'street_address');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'route');
    if (addressComponent) {
      if (address.addressLine1 && address.addressLine1.length > 0) {
        address.addressLine1 += ', ';
      }
      address.addressLine1 += addressComponent;
    }
    
    //set address line2
    address.addressLine2 = '';
    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'sublocality_level_5');
    if (addressComponent)
      address.addressLine2 += addressComponent;

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'sublocality_level_4');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'sublocality_level_3');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'sublocality_level_2');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'sublocality_level_1');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }
    
    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'administrative_area_level_5');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'administrative_area_level_4');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }

    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'administrative_area_level_3');
    if (addressComponent) {
      if (address.addressLine2 && address.addressLine2.length > 0) {
        address.addressLine2 += ', ';
      }
      address.addressLine2 += addressComponent;
    }
    
    //get city
    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'locality');
    if (addressComponent) {
      address.cityName = addressComponent;
    }
    else {
      addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'administrative_area_level_2');
      if (addressComponent) {
        address.cityName = addressComponent;
      }
    }
    
    //set state
    addressComponent = GooglePlaceHelper.getAddressComponent(addressComponents, 'administrative_area_level_1');
    if (addressComponent) {
      address.stateCode = addressComponent['short_name'];
      address.stateName = addressComponent['long_name'];
    }

    //set country
    addressComponent = GooglePlaceHelper.getAddressComponent(addressComponents, 'country');
    if (addressComponent) {
      address.countryCode = addressComponent['short_name'];
      address.countryName = addressComponent['long_name'];
    }

    //set postal code
    addressComponent = GooglePlaceHelper.getAddressComponentValue(addressComponents, 'postal_code');
    if (addressComponent)
      address.zip = addressComponent;

    if (place.geometry && place.geometry.location) {
      address.lat = place.geometry.location.lat();
      address.lng = place.geometry.location.lng();
    }

    return address;
  }

  static getAddressComponent(addressComponents: any, name: string) {
    return addressComponents.filter(address => address.types.indexOf(name) !== -1)[0];
  }

  static getAddressComponentValue(addressComponents: any, name: string, isLongName: boolean = true) {
    let addressComponent = null;
    let component = GooglePlaceHelper.getAddressComponent(addressComponents, name);
    if (component)
      addressComponent = component[isLongName ? "long_name" : "short_name"];
    return addressComponent;
  }
}
