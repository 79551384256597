import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Teacher } from 'src/app/_models/teacher';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexTeacherComponent implements OnInit {
  public justVerified = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    if (this.currentTeacherValue) {
      const count = localStorage.getItem('student_count') || 0;
      if (count > 0) {
        router.navigateByUrl('/student-list', { replaceUrl: true });
      } else if (route.snapshot.queryParams.verified == '1') {
        this.justVerified = true;
        const queryParams = JSON.parse(JSON.stringify(route.snapshot.queryParams));
        queryParams.verified = null;
        router.navigate([], { queryParams, queryParamsHandling: 'merge', replaceUrl: true });
      }
    }
  }

  ngOnInit() {
  }

  public get currentTeacherValue(): Teacher {
    return this.authenticationService.currentTeacherValue;
  }

}
