import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Teacher } from 'src/app/_models/teacher';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
  }

  public get currentTeacherValue(): Teacher {
    return this.authenticationService.currentTeacherValue;
  }

  teacherLogout() {
    this.authenticationService.teacherLogout();
    this.router.navigate(['/teacher/login']);
  }
}
