import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Role } from './_models/role';
import { Teacher } from './_models/teacher';
import { User } from './_models/user';
import { AuthenticationService } from './_services/authentication.service';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    title = 'my-app';
    currentUser: User;
    currentTeacher: Teacher;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private activatedRoute:    ActivatedRoute,
         private titleService: Title
    ) {
        this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data &&    child.snapshot.data['title']) {
                        return child.snapshot.data['title'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe( (data: any) => {
            if (data) {
                this.titleService.setTitle(data + ' - Talking Rhino');
            }
        });
    }

    get isAdmin() {
        return this.currentUser && this.currentUser.role === Role.Admin;
    }

    get isTeacher() {
        return this.currentTeacher && this.currentTeacher.role === Role.Teacher;
    }
    public onActivate() {
        setTimeout(() => {
          window.scrollTo(0, 0);
        });
      }
    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
}