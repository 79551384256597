export class Address {
  id: number;
  addressLine1: string;//premise, route
  addressLine2: string;//sublocality
  cityId: number;
  cityName: string;//locality,administrative_area_level_3
  stateId: number;
  stateName: string;//administrative_area_level_1    
  stateCode: string;
  countryId: number;
  countryName: string;
  countryCode: string;
  zip: number;
  lat: number;
  lng: number;

  static isValid(address: Address): boolean {
    if ((address.lat && address.lat) || (address.cityId > 0 && address.countryId > 0))
      return true;
    return false;
  }

  static formattedAddress(address: Address): string {
    let addressPart = [];
    if (address) {
      if (address.cityName)
        addressPart.push(address.cityName);

      if (address.stateName)
        addressPart.push(address.stateName);

      if (address.countryName)
        addressPart.push(address.countryName);
    }
    return addressPart.join(", ");
  }

  static getFullFormattedAddress(address: Address): string {
    let addressPart = [];
    // if (address.addressLine1)
    //   addressPart.push(address.addressLine1);

    // if (address.addressLine2)
    //   addressPart.push(address.addressLine2);

    if (address.cityName)
      addressPart.push(address.cityName);

    if (address.stateName)
      addressPart.push(address.stateName)

    if (address.countryName)
      addressPart.push(address.countryName);

    if (address.zip)
      addressPart.push(address.zip);

    return addressPart.join(", ");
  }
}
