// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng build --prod
//ng build --c=staging
//let baseURL = 'http://localhost:8000';
let baseURL='https://staging.api.talkingrhino.com';
//let baseURL='https://api.talkingrhino.com';
export const environment = {
  production: false,
  baseURL: baseURL,
  apiURL: baseURL + '/api',
  firebaseWebAPIKey: 'AIzaSyAmV1x_lRNlI9D1TgPCZiHQ5jmUshfHt8Y',
  perPage: 10,
  homeURL: 'https://staging.talkingrhino.com',
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
