import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';
declare var bodymovin: any;

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.css']
})
export class ComingSoonComponent implements OnInit {
  comingSoon: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success: boolean = false;
  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.comingSoon = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.comingSoon.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.comingSoon.invalid) {
      return;
    }
    //https://script.google.com/a/volgainfotech.com/macros/s/AKfycbyzid4BqA6TUP0rEjvuDZYRWMK9jAVhqm1V43rn/exec
    //https://script.google.com/a/volgainfotech.com/macros/s/AKfycbyzid4BqA6TUP0rEjvuDZYRWMK9jAVhqm1V43rn/exec
    this.loading = true;
    this.sharedService.create('create-signup-user', this.comingSoon.value)
      .pipe(first())
      .subscribe(
        data => {
          this.success = true;
          this.loading = false;
          setTimeout(() => {
            this.success = false;
            this.comingSoon.reset();
            this.submitted = false;
          }, 7000);
        },
        error => {
          this.error = error;
          this.loading = false;
        });

    this.sharedService.comingSoon(this.comingSoon.value)
      .pipe(first())
      .subscribe(
        data => {
          // this.success = data;
          // this.loading = false;
        },
        error => {
          // this.error = error;
          // this.loading = false;
        });
  }

  ngAfterViewInit() {
    bodymovin.loadAnimation({
      container: document.getElementById('flying-rhino'), // Required
      path: './assets/animation/Fly/data.json', // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });

    bodymovin.loadAnimation({
      container: document.getElementById('goggle-rhino'), // Required
      path: './assets/animation/Peep/data.json', // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });

    bodymovin.loadAnimation({
      container: document.getElementById('floating-rhino'), // Required
      path: './assets/animation/Swim/data.json', // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });

    bodymovin.loadAnimation({
      container: document.getElementById('team-rhino'), // Required
      path: './assets/animation/Hugging/data.json', // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });

    bodymovin.loadAnimation({
      container: document.getElementById('contact-rhino'), // Required
      path: './assets/animation/Regisration/data.json', // Required
      renderer: 'svg', // Required
      loop: true, // Optional
      autoplay: true, // Optional
    });
  }
}
