import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({ providedIn: 'root' })

export class TeacherNoAuth {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // redirect to home if already logged in
        if (this.authenticationService.currentTeacherValue) {
            this.router.navigate(['/teacher']);
            return false;
        }
        return true;        
    }
}
