import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { first } from 'rxjs/operators';
import { GooglePlaceHelper } from 'src/app/_helper/google-place-helper';
import { MustMatchService } from 'src/app/_helper/must-match.service';
import { Address } from 'src/app/_models/address';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { CustomValidatorsService } from 'src/app/_helper/custom-validators.service';
declare var $;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('addressDom', { static: true }) addressDom: ElementRef;
  @ViewChild('schoolName', { static: false, read: AutocompleteComponent }) private schoolName: AutocompleteComponent;
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  street_number = "";
  public address: Address;
  city = "";
  state = "";
  country = "";
  zip = "";
  countryList: any;
  keyword = 'school_name';
  isDataLoading = false;
  isAddressSelected = false;
  data: any;
  imgChangeEvt: any = "";
  cropImgPreview: any = null;
  cropImgPreviewForm: any = null;
  public isLocationNotValid: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private customValidators: CustomValidatorsService,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService
  ) {

  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      salutation: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      addressText: ['', [Validators.required, Validators.maxLength(190)]],
      country: [''],
      countryName: [''],
      city: [''],
      zipCode: [''],
      state: [''],
      school: ['', [Validators.required, Validators.maxLength(190)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(190)]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(190)]],
      rePassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(190)]],
      acceptTerms: [false, Validators.requiredTrue]
    }, {
      validator: MustMatchService('password', 'rePassword')
    });
    this.getCountry();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterContentChecked() {
    setTimeout(() => {
      if (this.schoolName && this.schoolName.searchInput && this.schoolName.searchInput.nativeElement) {
        this.schoolName.searchInput.nativeElement.maxLength = 190;
      }
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  //get schools from remote api
  selectEvent(item) {
    // do something with selected item https://www.npmjs.com/package/angular-ng-autocomplete#api
    // console.log(item)
    // this.registerForm.patchValue({'school':item.school_name});
  }

  getCountry() {
    // fetch remote data from here    
    this.sharedService.getData('api/get-country')
      .subscribe(res => {
        this.countryList = res;
      })
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    if (val && this.registerForm.value.city) {
      this.isDataLoading = true;
      this.sharedService.create('get-schools', { 'keyword': val, 'country_id': this.registerForm.value.country, 'city': this.registerForm.value.city, 'state': this.registerForm.value.state, 'by_name': true })
        .subscribe(res => {
          this.data = res;
          this.isDataLoading = false;
        })
    }
  }

  onFocused(e) {
    // do something when input is focused

  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid || !this.isAddressSelected) {
      return;
    }
    this.loading = true;
    // this.registerForm.value.countryName = this.country;
    // this.registerForm.value.profile_pic = this.cropImgPreview;
    this.registerForm.value.profile_pic = this.cropImgPreviewForm;
    this.sharedService.create('teacher/registration', this.registerForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate(['success-register', this.registerForm.value.email]);
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  // reset form
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }

  //https://www.thecodehubs.com/how-to-add-google-places-autocomplete-address-form-in-angular/
  /*placeChangedCallback(place: any) {    
    this.isAddressSelected = true;
    this.street_number = "";
    this.address = "";
    this.city = "";
    this.state = "";
    this.country = "";
    this.zip = "";

    const addressFrom = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      sublocality_level_1: "sublocality_level_1",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };

    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        if (addType == "street_number")
          this.street_number = add.short_name;
        if (addType == "route")
          this.address = add.long_name;
        if (addType == "locality" || addType == "sublocality_level_1")
          this.city = add.long_name;
        if (addType == "administrative_area_level_1")
          this.state = add.long_name;
        if (addType == "country")
          this.country = add.long_name;
        if (addType == "postal_code")
          this.zip = add.long_name;
      });
    });
    this.registerForm.patchValue({
      'addressText': this.addressDom.nativeElement.value,
      'country': this.country,
      'city': this.city,
      'state': this.state,
      'zipCode': this.zip
    });
  }*/

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }
  openProfileUploadPopup() {
    $('#popup_profile_upload').modal('show');
  }
  setPicture() {
    this.cropImgPreviewForm = this.cropImgPreview;
    $('#popup_profile_upload').modal('hide');
  }
  focusOutAddress() {
    if (!this.isAddressSelected) {
      this.registerForm.patchValue({
        addressText: null
      });
    }
  }
  removePic(fileInput) {
    this.cropImgPreview = null;
    this.cropImgPreviewForm = null;
    this.imgChangeEvt = "";
    fileInput.value = "";
  }

  onBlurLocation(e) {
    if (e == '')
      this.isLocationNotValid = true;
    else
      this.isLocationNotValid = false;
    this.focusOutAddress();
  }

  public clearAddress(place: any): void {
    this.address = null;
    this.isLocationNotValid = false;
    this.isAddressSelected = false;
  }

  public getAddress(place: any): void {
    if (place) {

      this.address = GooglePlaceHelper.getAddress(place);
      this.isAddressSelected = true;
    }
    console.log(this.address)
    this.registerForm.patchValue({
      'addressText': Address.getFullFormattedAddress(this.address),
      'country': this.address.countryName,
      'countryName': this.address.countryName,
      'city': this.address.cityName,
      'state': this.address.stateName,
      'zipCode': this.address.zip
    });
    setTimeout(() => {
      if (this.schoolName && this.schoolName.searchInput && this.schoolName.searchInput.nativeElement) {
        this.schoolName.searchInput.nativeElement.maxLength = 190;
      }
    });
    // reset schools autocomplete list
    this.data = [];
    // FormHelper.setFormControlValue(this.crowdfundForm, "location", Address.getFullFormattedAddress(this.address));
  }
}
