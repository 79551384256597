import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient) { }
  public getData = (route: string) => {
    return this.http.get(this.createCompleteRoute(route, environment.baseURL));
  }
  // public getRawData(route: string, body): Observable<any>{
  public getRawData(route: string, body): Observable<any> {
    return this.http.get(environment.apiURL + '/' + route, { params: body });
  }
  public create = (route: string, body) => {
    return this.http.post(this.createCompleteRoute(route, environment.apiURL), body, this.generateHeaders());
  }

  public update = (route: string, body): Observable<any> => {
    return this.http.put(this.createCompleteRoute(route, environment.apiURL), body, this.generateHeaders());
  }

  public delete = (route: string) => {
    return this.http.delete(this.createCompleteRoute(route, environment.apiURL));
  }

  private createCompleteRoute = (route: string, envAddress: string) => {
    return `${envAddress}/${route}`;
  }

  getPDF(): Observable<Blob> {
    return this.http.get(environment.apiURL + '/exporttopdf', { responseType: 'blob' });
  }

  getExcel(): Observable<Blob> {
    return this.http.get(environment.apiURL + '/exporttoexcel', { responseType: 'blob' });
  }

  private generateHeaders = () => {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
  }

  comingSoon(param) {
    return this.http.post('https://script.google.com/a/volgainfotech.com/macros/s/AKfycbyzid4BqA6TUP0rEjvuDZYRWMK9jAVhqm1V43rn/exec', param,
      {
        headers: new HttpHeaders({ 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Origin' : '*'
         })
      });
  }
}
