import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  hasOwnProperty = Object.prototype.hasOwnProperty;
  tokenFromUI: string = "0123456789123456";

  constructor() { }
  /**
   * function return true / false weater given param is emplty or not
   * @param pass array, string, object
   */
  isEmpty(obj) {

    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (var key in obj) {
      if (this.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }
  /**
   * Below function returns the date following by object e.g { key: 8, value: "year" }
   * @param obj 
   */
  getFromDate(obj) {
    var seletedDays;
    if (obj.value == 'day' || obj.value == 'days') {
      seletedDays = obj.key;
    }
    else if (obj.value == 'week' || obj.value == 'weeks') {
      seletedDays = obj.key * 7;
    }
    else if (obj.value == 'month' || obj.value == 'months') {
      seletedDays = obj.key * 30;
    }
    else if (obj.value == 'year' || obj.value == 'years') {
      seletedDays = obj.key * 365;
    }
    let now = new Date();
    now.setTime(now.getTime() - seletedDays * 24 * 60 * 60 * 1000);
    let fromDate = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    return fromDate;
  }

  /**
   * Redirect to link for new tab
   */
  webSiteRedirect(obj) {
    let hrefObj = 'http://' + obj;
    window.open(hrefObj, '_blank');
  }

  /**
   * Sorting by Key and Value
   */
  sortByKeyValue(array, key, order = 'asc') {
    if (order === 'desc') {
      // descending
      return array.sort((a, b) => b[key] - a[key]);
    } else {
      //ascending
      return array.sort((a, b) => a[key] - b[key]);
    }
  }

  /**
   * Group by Key
   */
  groupByArrayKey(array, key) {
    var groups = new Set(array.map(item => item[key]))
    let groupArray = [];
    groups.forEach(g =>
      groupArray.push({
        name: g,
        values: array.filter(i => i[key] === g)
      }
      ));
    return groupArray;
  }
  /**
   * below function compare two object and return true or false
   * @param obj1 
   * @param obj2 
   */
  isEqual(obj1, obj2) {
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    if (obj1Keys.length !== obj2Keys.length) {
      return false;
    }
    for (let objKey of obj1Keys) {
      if (obj1[objKey] !== obj2[objKey]) {
        if (typeof obj1[objKey] == "object" && typeof obj2[objKey] == "object") {
          if (!this.isEqual(obj1[objKey], obj2[objKey])) {
            return false;
          }
        }
        else {
          return false;
        }
      }
    }
    return true;
  };
  /**
   * below function returns encrypted String of given string
   * @param text receive variable in string
   */
  encryptUsingAES256(text) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(text), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  /**
   * below function returns dycrypt string  
   * @param text receive encryted string 
   */
  decryptUsingAES256(text) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

    return CryptoJS.AES.decrypt(
      text, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
  }

  groupByDate(collection: Array<any>, property: string = 'date'): Array<any> {
    if (!collection) {
      return null;
    }
    const gc = collection.reduce((previous, current) => {
      if (!previous[current[property]]) {
        previous[current[property]] = [];
      }
      current.events.forEach(x => previous[current[property]].push(x));
      return previous;
    }, {});
    return Object.keys(gc).map(date => ({ date: date, events: gc[date] }));
  }
}
