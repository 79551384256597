import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; // CLI imports router
import { AdminComponent } from './admin/admin.component';
import { HomeComponent } from './home/home.component';
import { KiddictionComponent } from './admin/kiddiction/kiddiction.component';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { ParentUsersComponent } from './admin/parent-users/parent-users.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { TrComponent } from './admin/tr/tr.component';
import { AuthGuard } from './_guards/auth.guard';
import { Role } from './_models/role';
import { FaqComponent } from './front/faq/faq.component';
import { FrontComponent } from './front/front.component';
import { TermsOfUseComponent } from './front/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './front/privacy-policy/privacy-policy.component';
import { TeacherComponent } from './teacher/teacher.component';
import { TeacherLoginComponent } from './teacher/teacher-login/teacher-login.component';
import { DashboardComponent } from './teacher/dashboard/dashboard.component';
import { RegisterComponent } from './teacher/register/register.component';
import { ForgotPasswordComponent } from './teacher/forgot-password/forgot-password.component';
import { TeacherAuth } from './_guards/teacher-auth';
import { AddStudentComponent } from './teacher/add-student/add-student.component';
import { TeacherNoAuth } from './_guards/teacher-no-auth';
import { StudentListComponent } from './teacher/student-list/student-list.component';
import { RegistrationSuccessComponent } from './teacher/registration-success/registration-success.component';
import { AccountVerifiedComponent } from './teacher/account-verified/account-verified.component';
import { StudentDetailComponent } from './teacher/student-detail/student-detail.component';
import { EditProfileComponent } from './teacher/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './teacher/change-password/change-password.component';
import { MobileComponent } from './front/mobile/mobile.component';
import { ComingSoonComponent } from './front/coming-soon/coming-soon.component';
import { IndexComponent } from './front/index/index.component';
import { IndexTeacherComponent } from './teacher/index/index.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent,
  //   // canActivate: [AuthGuard]
  // },
  // {
  //   path: '',
  //   component: ComingSoonComponent
  // },
  /*{
    path: '',
    component: FrontComponent,
    children: [
      //{ path: '', component: IndexComponent, data: { title: 'Audio Stories for Children' } },
      { path: '', component: IndexTeacherComponent, data: { title: 'Educator' } },
      // { path: 'index', component: IndexComponent, data: { title: 'FAQs' } },
      { path: 'faq', component: FaqComponent, data: { title: 'FAQs' } },
      { path: 'terms-of-use', component: TermsOfUseComponent, data: { title: 'Terms of Use' } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
    ]
  },
  {
    path: 'app',
    component: MobileComponent,
    children: [
      { path: 'faq', component: FaqComponent, data: { title: 'FAQs' } },
      { path: 'terms-of-use', component: TermsOfUseComponent, data: { title: 'Terms of Use' } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
    ]
  },*/
  {
    path: '',
    component: TeacherComponent,
    data: { roles: [Role.Teacher] },
    children: [
      { path: '', component: IndexTeacherComponent, data: { title: 'Educator' } },
      { path: 'sign-in', component: DashboardComponent, data: { title: 'Educator' } },
      { path: 'register', component: RegisterComponent, canActivate: [TeacherNoAuth], data: { title: 'Register' } },
      { path: 'success-register/:email', component: RegistrationSuccessComponent, canActivate: [TeacherNoAuth], data: { title: 'Register Success', email: null } },
      { path: 'verified/:id', component: AccountVerifiedComponent, canActivate: [TeacherNoAuth], data: { title: 'Verification' } },
      { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [TeacherNoAuth], data: { title: 'Forgot Password' } },
      { path: 'login', component: TeacherLoginComponent, canActivate: [TeacherNoAuth], data: { title: 'Login' } },
      { path: 'edit-profile', component: EditProfileComponent, canActivate: [TeacherAuth], data: { title: 'Edit Profile' } },
      { path: 'change-password', component: ChangePasswordComponent, canActivate: [TeacherAuth], data: { title: 'Change Password' } },
      { path: 'add-student', component: AddStudentComponent, canActivate: [TeacherAuth], data: { title: 'Add Student' } },
      { path: 'student-list', component: StudentListComponent, canActivate: [TeacherAuth], data: { title: 'Student List' } },
      { path: 'student-detail/:id/:code', component: StudentDetailComponent, canActivate: [TeacherAuth], data: { title: 'Student' } },
      { path: 'terms-of-use', component: TermsOfUseComponent, data: { title: 'Terms of Use' } },
      { path: 'faq', component: FaqComponent, data: { title: 'FAQs' } },
      { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
    ]
  },
  {
    path: 'god',
    component: AdminComponent,
    data: { roles: [Role.Admin] },
    children: [
      { path: '', component: DashboardComponent, data: { title: 'FAQs' } },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
      { path: 'kiddiction', component: KiddictionComponent, canActivate: [AuthGuard] },
      { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard] },
      { path: 'users', component: ParentUsersComponent, canActivate: [AuthGuard] },
      { path: 'tr', component: TrComponent, canActivate: [AuthGuard] },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent
  },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

// configures NgModule imports and exports
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'top',
    scrollOffset: [0, 0]
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
