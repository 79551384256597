import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTabsetConfig } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { HelperService } from 'src/app/_helper/helper.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.css']
})
export class StudentDetailComponent implements OnInit {
  public id: number;
  public code: any;
  public teacherId: number;
  contentLoading: boolean = false;
  error: boolean = false;
  data: any;
  studentData: any;
  tabProperty: any;
  tabSubline: any;

  constructor(
    config: NgbTabsetConfig,
    private helper: HelperService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService,
    private titleService: Title
  ) {
    // customize default values of tabsets used by this component tree
    config.justify = 'center';
    config.type = 'pills';
  }

  ngOnInit() {
    this.id = parseInt(this.helper.decryptUsingAES256(decodeURIComponent(this.route.snapshot.paramMap.get('id'))));
    this.code = decodeURIComponent(this.route.snapshot.paramMap.get('code'));
    this.teacherId = this.authenticationService.currentTeacherValue.id;
    this.getTimeAndCoverageData();
  }

  getTimeAndCoverageData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child/' + this.teacherId + '/' + this.id + '/' + encodeURIComponent(this.code), null)
      .pipe(first())
      .subscribe(
        res => {
          // console.log(res.status);
          if (res.status == true) {
            this.data = res.data;
            this.studentData = res.student_data;
            this.titleService.setTitle(this.studentData.fullname + " - Progress Card");
            this.contentLoading = false;
            this.tabProperty = [
              {
                subline: "Take a look at all that " + this.studentData.fullname + "'s covered!"
              },
              {
                subline: this.studentData.fullname + " enjoyed answering these questions the most! Have a look:"
              },
              {
                subline: "Scroll to view all of " + this.studentData.fullname + "'s answers to the story quizzes:"
              },
              {
                subline: this.studentData.fullname + " needs a little more practice to pronounce these words correctly:"
              }
            ];
            this.tabSubline = this.tabProperty[0];
          } else {
            // this.router.navigate(['/student-list']);
          }
        },
        error => {
          this.error = error;
          this.contentLoading = false;
        });
  }

  changeSubTitle(e) {
    console.log(e)
    // if (e.nextId == '0') {
    this.tabSubline = this.tabProperty[e.nextId];
    // }
    // else if (e.nextId == '1') {
    //   this.tabSubline = this.tabProperty[1];
    // }
    // this.tabSubline = this.tabProperty[index];
  }
}
