import { AfterContentInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { GooglePlaceHelper } from 'src/app/_helper/google-place-helper';
import { Address } from 'src/app/_models/address';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { TeacherService } from 'src/app/_services/teacher.service';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { CustomValidatorsService } from 'src/app/_helper/custom-validators.service';
declare var $;

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit, AfterContentInit {
  @ViewChild('addressDom', { static: true }) addressDom: ElementRef;
  @ViewChild('schoolName', { static: false, read: AutocompleteComponent }) private schoolName: AutocompleteComponent;
  editProfileForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  success: any;
  street_number = "";
  public address: Address;
  city = "";
  state = "";
  country = "";
  zip = "";
  countryList: any;
  isDataLoading = false;
  isFormDataLoading = false;
  isAddressSelected = false;
  keyword = 'school_name';
  data: any;
  editData: any;
  imgChangeEvt: any = "";
  cropImgPreview: any = null;
  cropImgPreviewForm: any = null;
  genderList: any = [{ "name": "Male", "value": "male" }, { "name": "Female", "value": "female" }];
  private messageSubscription: Subscription;
  public isLocationNotValid: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private messengerService: TeacherService,
    private customValidators: CustomValidatorsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
    this.getCountry();
    this.editProfileForm = this.formBuilder.group({
      salutation: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      addressText: ['', [Validators.required, Validators.maxLength(190)]],
      country: ['', Validators.required],
      countryName: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      school: ['', [Validators.required, Validators.maxLength(190)]],
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email, Validators.maxLength(190)]],
      gender: [''],
      id: ['', Validators.requiredTrue]
    });
    this.assignFormData();
  }
  assignFormData() {
    this.editData = this.authenticationService.currentTeacherValue;
    this.sharedService.getRawData('teacher/get-teacher/' + this.editData.id, null)
      .pipe(first())
      .subscribe(
        res => {
          var data = res.data;
          this.cropImgPreviewForm = data.profile_picture;
          this.cropImgPreview = data.profile_picture;
          console.log(data);
          // this.cropImgPreview = data.profile_picture;
          this.editProfileForm = this.formBuilder.group({
            id: [this.editData.id, Validators.required],
            salutation: [data.salutation, Validators.required],
            firstName: [data.first_name, [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
            lastName: [data.last_name, [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
            country: [data.country_data ? parseInt(data.country_data.id) : data.country, Validators.required],
            addressText: [data.address, [Validators.required, Validators.maxLength(190)]],
            city: [data.city],
            state: [data.state],
            zipCode: [data.zipcode],
            school: [data.school.school_name, [Validators.required, Validators.maxLength(190)]],
            gender: [data.gender],
            email: [{ value: data.email, disabled: true }, [Validators.required, Validators.email, Validators.maxLength(190)]],
          });
          this.isFormDataLoading = true;
        },
        error => {
          this.error = error;
        });
  }

  ngAfterContentInit() {
    setTimeout(() => {
      if (this.schoolName && this.schoolName.searchInput && this.schoolName.searchInput.nativeElement) {
        this.schoolName.searchInput.nativeElement.maxLength = 190;
      }
    });
  }
  
  // convenience getter for easy access to form fields
  get f() { return this.editProfileForm.controls; }

  getCountry() {
    // fetch remote data from here    
    this.sharedService.getData('api/get-country')
      .subscribe(res => {
        this.countryList = res;
      })
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    if (val && this.editProfileForm.value.city) {
      this.isDataLoading = true;
      this.sharedService.create('get-schools', { 'keyword': val, 'country_id': this.editProfileForm.value.country, 'city': this.editProfileForm.value.city, 'state': this.editProfileForm.value.state, 'by_name': true })
        .subscribe(res => {
          this.data = res;
          this.isDataLoading = false;
        })
    }
  }
  selectEvent(item) {
    // do something with selected item https://www.npmjs.com/package/angular-ng-autocomplete#api
    // console.log(item)
    // this.registerForm.patchValue({'school':item.school_name});
  }
  onFocused(e) {
    // do something when input is focused

  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editProfileForm.invalid || !this.isAddressSelected) {
      return;
    }
    this.loading = true;
    this.editProfileForm.value.countryName = this.editProfileForm.value.country;
    this.editProfileForm.value.profile_pic = this.cropImgPreview;
    this.sharedService.update('teacher/edit-profile/' + this.editProfileForm.value.id, this.editProfileForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.success = data;
          setTimeout(() => this.success = null, 6000);
          this.loading = false;
          this.sharedService.getRawData('teacher/get-teacher/' + this.editData.id, null)
            .pipe(first())
            .subscribe(
              res => {
                this.messengerService.setMessage(res.data);
                localStorage.setItem('currentTeacher', JSON.stringify(res.data));
                this.assignFormData();
              },
              error => {
                if (error && typeof(error) == "object") {
                  if (error.message && typeof(error.message) == "object") {
                    this.error = error.message[Object.keys(error.message)[0]] || 'Unknown error';
                  } else {
                    this.error = error.message;
                  }
                } else {
                  this.error = error;
                }
              });
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  // reset form
  onReset() {
    this.submitted = false;
    this.editProfileForm.reset();
  }

  //https://www.thecodehubs.com/how-to-add-google-places-autocomplete-address-form-in-angular/
  /*placeChangedCallback(place: any) {
    this.isAddressSelected = true;
    this.street_number = "";
    this.address = "";
    this.city = "";
    this.state = "";
    this.country = "";
    this.zip = "";

    const addressFrom = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      sublocality_level_1: "sublocality_level_1",
      administrative_area_level_1: "short_name",
      country: "long_name",
      postal_code: "short_name",
    };
    place.address_components.forEach((add: any) => {
      add.types.forEach((addType: any) => {
        if (addType == "street_number")
          this.street_number = add.short_name;
        if (addType == "route")
          this.address = add.long_name;
        if (addType == "locality" || addType == "sublocality_level_1")
          this.city = add.long_name;
        if (addType == "administrative_area_level_1")
          this.state = add.long_name;
        if (addType == "country")
          this.country = add.long_name;
        if (addType == "postal_code")
          this.zip = add.long_name;
      });

    });
    this.editProfileForm.patchValue({
      'addressText': this.addressDom.nativeElement.value,
      'country': this.country,
      'city': this.city,
      'state': this.state,
      'zipCode': this.zip
    });
  }*/

  onFileChange(event: any): void {
    this.imgChangeEvt = event;
  }
  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
  }

  imgLoad() {
    // display cropper tool
  }

  initCropper() {
    // init cropper
  }

  imgFailed() {
    // error msg
  }
  openProfileUploadPopup() {
    $('#popup_profile_upload').modal('show');
  }
  setPicture() {
    this.cropImgPreviewForm = this.cropImgPreview;
    $('#popup_profile_upload').modal('hide');
  }
  focusOutAddress() {
    if (!this.isAddressSelected) {
      this.editProfileForm.patchValue({
        addressText: null
      });
    }
  }
  onBlurLocation(e) {
    if (e == '')
      this.isLocationNotValid = true;
    else
      this.isLocationNotValid = false;
    this.focusOutAddress();
  }

  public clearAddress(place: any): void {
    this.address = null;
    this.isLocationNotValid = false;
    this.isAddressSelected = false;
  }

  public getAddress(place: any): void {
    if (place) {

      this.address = GooglePlaceHelper.getAddress(place);
      this.isAddressSelected = true;
    }
    console.log(this.address)
    this.country = this.address.countryName;
    this.editProfileForm.patchValue({
      'addressText': Address.getFullFormattedAddress(this.address),
      'country': this.address.countryName,
      'countryName': this.address.countryName,
      'city': this.address.cityName,
      'state': this.address.stateName,
      'zipCode': this.address.zip
    });
    // reset schools autocomplete list
    this.data = [];
    // FormHelper.setFormControlValue(this.crowdfundForm, "location", Address.getFullFormattedAddress(this.address));
  }
}
