import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css']
})
export class FrontComponent implements OnInit, OnDestroy, AfterContentInit {
  private subscription: Subscription = null;
  private fragment: String;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscription = this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterContentInit() {
    if (this.fragment) {
      const el = document.querySelector('#' + this.fragment);
      if (el) {
        setTimeout(() => {
          el.scrollIntoView();
        });
      }
    }
  }
}
