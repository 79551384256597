import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'tab-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  @Input() teacherId: number;
  @Input() childId: number;
  contentLoading: boolean = false;
  error: boolean = false;
  data: any;
  page: any;
  timeSpent: any;
  lastActivity: any;
  stories: any;
  checkpoints: any;
  total_count: any;
  loadingMore: boolean = false;

  constructor(
    private sharedService: SharedService,
  ) {
    this.page = {
      "pageNo": 1,
      "pageSize": 20
    };
  }

  ngOnInit() {
    this.getTimeAndCoverageData();
    this.getScoreData();
  }
  getTimeAndCoverageData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/time-and-coverage', null)
      .pipe(first())
      .subscribe(
        res => {
          this.timeSpent = res.data.time_spent;
          this.lastActivity = res.data.last_activity;
          this.stories = res.data.stories;
          // this.contentLoading = false;
        },
        error => {
          this.error = error;
          // this.contentLoading = false;
        });
  }

  getScoreData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/scores', this.page)
      .pipe(first())
      .subscribe(
        res => {
          this.checkpoints = res.data.checkpoints;
          this.total_count = res.data.total_count;
          this.contentLoading = false;
        },
        error => {
          this.error = error;
          this.contentLoading = false;
        });
  }

  calculateScore(data, who) {
    if (who === 'first') {
      return (data.p1_score < 500 ? data.p1_score : 500) + " / " + (data.questions_answered_player1 * 10);
    } else {
      return (data.p2_score < 500 ? data.p2_score : 500) + " / " + (data.questions_answered_player2 * 10);
    }
  }

  formatDuration(duration) {
    var seconds = Math.abs(Math.ceil(duration / 1000)),
      h = (seconds - seconds % 3600) / 3600,
      m = (seconds - seconds % 60) / 60 % 60,
      s = seconds % 60;
    return (h > 0 ? (duration < 0 ? '-' : '') + h + ' hrs ' : "") + m.toString() + ' mins';// + s.toString();
  }
  onScroll(e) {
    if (this.checkpoints.length == this.total_count) {
      console.log('No more items');
      return;
    }
    this.loadingMore = true;
    this.page.pageNo = this.page.pageNo + 1;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/scores', this.page)
      .pipe(first())
      .subscribe(
        res => {
          res.data.checkpoints.map(item => {
            this.checkpoints.push(item);
          });
          console.log(this.checkpoints);
          this.loadingMore = false;
        },
        error => {
          this.error = error;
          // this.loadingMore = false;
        });
  }
  clickToTop() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('process-card-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}
