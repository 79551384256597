import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $;

@Component({
  selector: 'tab-favorites',
  templateUrl: './favorites.component.html',
  providers: [NgbCarouselConfig],  // add NgbCarouselConfig to the component providers
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  @Input() teacherId: number;
  @Input() childId: number;
  contentLoading: boolean = false;
  error: boolean = false;
  data: any;
  page: any;  
  slideConfig = { "slidesToShow": 3, "slidesToScroll": 1, variableWidth: true, infinite: false };
  public favoriteTrack = (index, item) => item.id;

  constructor(
    private sharedService: SharedService,
  ) {
    this.page = {
      "pageNo": 1,
      "pageSize": 1000
    };
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/favorites', this.page)
      .pipe(first())
      .subscribe(
        res => {
          this.data = res.data.favorites.filter(function(item) {
            if (item.user_answers.length == 0) {
              return false; // skip
            }
            return true;
          }).map(function(item) { return item; });
          // console.log(this.data)
          this.data = this.splitArr(this.data, 5)          
          // this.data.map(item => item.forEach(citem => citem.user_answers.push(...citem.user_answers)));
          console.log(this.data)
          // this.data = res.data.favorites;
          this.contentLoading = false;
        },
        error => {
          this.error = error;
          this.contentLoading = false;
        });
  }
  
  color(index) {
    switch (index) {
      case 0:
        return "yellow-color";
        break;
      case 1:
        return "blue-color";
        break;
      case 2:
        return "pink-color";
        break;
      case 3:
        return "red-color";
        break;
      case 4:
        return "purple-color";
        break;
    }
  }

  splitArr(arr, size) {
    let newArr = [];
    for (let i = 0; i < arr.length; i += size) {
      newArr.push(arr.slice(i, i + size));
    }
    return newArr;
  }

  addSlide() {

  }

  removeSlide() {

  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

  openTypesOfQuestionsPopup(){
    $('#types-of-questions-popup').modal('show');
  }

  clickToTop() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('process-card-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}
