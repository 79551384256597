import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MustMatchService } from 'src/app/_helper/must-match.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  loading = false;
  submitted = false;
  error = '';
  success:any;
  editData: any;

  constructor(
    private formBuilder: FormBuilder,    
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.editData = this.authenticationService.currentTeacherValue;
    this.changePasswordForm = this.formBuilder.group({
      id: [this.editData.id, Validators.required],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(190)]],
      rePassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(190)]],
      
    }, {
      validator: MustMatchService('password', 'rePassword')
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }
    this.loading = true;
    this.sharedService.update('teacher/change-password/'+this.changePasswordForm.value.id, this.changePasswordForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.success = data;
          setTimeout(() => this.success = null, 6000);
          this.onReset();
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  // reset form
  onReset() {
    this.submitted = false;
    this.changePasswordForm.reset();
  }

}
