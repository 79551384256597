import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Teacher } from 'src/app/_models/teacher';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { TeacherService } from 'src/app/_services/teacher.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  loginUser: any;

  private messageSubscription: Subscription;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private messengerService: TeacherService
  ) { }

  ngOnInit() {
    // this.loginUser = this.authenticationService.currentTeacherValue;
    this.messageSubscription = this.messengerService.message.subscribe(m => {
      if (m == null && this.authenticationService.currentTeacherValue) {
        this.messengerService.setMessage(this.authenticationService.currentTeacherValue);
        this.messengerService.getFirebaseAuthenticated().subscribe(m => {
          if (m) {
            this.loginUser = m;
          }
        });
        console.log(this.loginUser);
      } else {
        this.loginUser = m;
      }

    });
  }
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
  }
  public get currentTeacherValue(): Teacher {
    return this.authenticationService.currentTeacherValue;
  }

  teacherLogout() {
    this.authenticationService.teacherLogout();
    this.messengerService.setMessage(null);
    this.router.navigate(['/teacher/login']);
  }
}
