import { Directive, Input, Output, EventEmitter, ElementRef } from '@angular/core';
declare var google: any;

@Directive({
  selector: '[googleplace]'
})
export class GooglePlaceDirective {

  @Input("googleplace") placeTypes: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  autocomplete: any;
  public _el: HTMLElement;


  constructor(public el: ElementRef) {
      this._el = el.nativeElement;        
  }

  ngOnInit() {
    
      var input = this._el;
      //(cities)
      let autoCompleteOptions = {};
      if (this.placeTypes)
          autoCompleteOptions = { types: [this.placeTypes] }

      this.autocomplete = new google.maps.places.Autocomplete(input, autoCompleteOptions);
      google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
          var place = this.autocomplete.getPlace();
          this.invokeEvent(place);
      });
  }

  invokeEvent(place: Object) {
      this.setAddress.emit(place);
  }

}
