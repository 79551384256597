import { Component, ElementRef, OnInit, Type, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { columnGroupWidths, ColumnMode } from '@swimlane/ngx-datatable';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { HelperService } from 'src/app/_helper/helper.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.css']
})
export class StudentListComponent implements OnInit {
  @ViewChild('searchInput', { static: true }) searchInput;
  loading = false;
  loadingform = false;
  rows = [];
  page: any;
  error: any;
  success: any;
  studentPasswordChangeForm: FormGroup;
  submitted = false;
  popup_property = false;
  popup_csv_upload = false;
  fieldTextType: boolean;
  errorForm = false;
  teacherId: number;
  ColumnMode = ColumnMode;
  isFilterAction = false;
  noDataMsg = "To add Student(s) click on the Add Student or Import Student list button";
  resendPasscodeFields: any = { id: null, password: null, teacher_id: null };
  deleteRow: any;
  editRow: any;
  now: any;
  public child: any;
  private isInitialized = false;

  constructor(
    private sharedService: SharedService,
    private helper: HelperService,
    private formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private router: Router,
    route: ActivatedRoute,
  ) {
    this.teacherId = this.authenticationService.currentTeacherValue.id;
    this.page = {
      "teacher_id": this.authenticationService.currentTeacherValue.id,
      "keyword": null,
      "teacher": null,
      "school": null,
      "sortBy": "created_at",
      "sortOrder": "desc",
      "page": 0,
      "perPage": environment.perPage,
      "totalElements": null
    };
    
    const page = Number(route.snapshot.queryParams.page);
    if (!isNaN(page)) {
      this.page.page = page - 1;
    }
  }

  ngOnInit() {
    this.getTableData();
  }

  ngAfterViewInit() {
    this.searchInput.valueChanges // this is (modelChange)
      .pipe(debounceTime(400))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        if (this.isInitialized || (value && value.length > 0)) {
          this.page.keyword = value;
          this.page.page = 0;
          this.isFilterAction = true;
          this.getTableData();
        }
      });

  }

  // convenience getter for easy access to form fields
  get f() { return this.studentPasswordChangeForm.controls; }

  //grid url http://swimlane.github.io/ngx-datatable/
  getTableData() {
    this.loading = true;
    this.sharedService.getRawData('teacher/get-students', this.page)
      .pipe(first())
      .subscribe(
        res => {
          this.rows = res.data;
          this.page.totalElements = res.total;
          localStorage.setItem('student_count', res.total || 0);
          this.loading = false;
          if (this.rows.length <= 0 && this.isFilterAction == true && this.page.keyword != null) {
            this.noDataMsg = 'No data found for "' + this.page.keyword + '"';
          }
          if (!this.isInitialized) {
            this.isInitialized = true;
          }
        },
        error => {
          this.error = error;
          this.loading = false;
          if (!this.isInitialized) {
            this.isInitialized = true;
          }
        });
  }
  onSort(event) {
    // event was triggered, start sort sequence
    console.log('Sort Event', event);
    console.log(event.column.prop)
    console.log(event.newValue)
    this.page.sortBy = event.column.prop;
    this.page.sortOrder = event.newValue;
    this.page.page = 0;
    this.getTableData();
  }
  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    console.log(pageInfo);
    this.page.page = pageInfo.offset;
    this.sharedService.getRawData('teacher/get-students', this.page)
      .pipe(first())
      .subscribe(
        res => {
          localStorage.setItem('student_count', res.total || 0);
          this.rows = res.data;
          this.router.navigate([], { queryParams: { page: this.page.page + 1 }, replaceUrl: true });
          this.loading = false;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  getEncryptData(id) {
    return encodeURIComponent(this.helper.encryptUsingAES256(id));
  }
  encodeURIComponent(code) {
    return encodeURIComponent(code);
  }
  /*getPDFFile() {
    this.scoreService.getPDF()
        .subscribe(x => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([x], { type: "application/pdf" });
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, "score_list.pdf");
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = "score_list.pdf";
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        });
}    
getExcelFile() {
    this.scoreService.getExcel()
        .subscribe(x => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([x], { type: "application/xlsx" });
            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob, "score_list.xlsx");
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            var link = document.createElement('a');
            link.href = data;
            link.download = "score_list.xlsx";
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        });
}*/

  openPasswordPopup(row) {
    this.resendPasscodeFields = { id: null, password: null, teacher_id: null };
    this.popup_property = true;
    this.fieldTextType = false;
    this.errorForm = null;
    this.success = null;
    //console.log(row.password.split("@" + row.teacher_id)[0]);
    $('#password_popup').modal('show');
    // this.studentPasswordChangeForm = this.formBuilder.group({
    //   id: [row.id],
    //   teacher_id: [row.teacher_id],
    //   password: [row.password]
    // });
    console.log(row);
    this.resendPasscodeFields = { id: row.id, password: row.password, teacher_id: row.teacher_id, first_name: row.first_name };
  }

  openCSVUploadPopup() {
    this.popup_csv_upload = true;
    this.now = Date.now();
    $('#popup_csv_upload').modal({ backdrop: 'static', keyboard: false });
  }

  /**
   * Resend passcode
   */
  onSubmit() {
    this.submitted = true;
    this.errorForm = false;
    this.loadingform = true;
    this.sharedService.create('teacher/send-passcode', this.resendPasscodeFields)
      .pipe(first())
      .subscribe(
        data => {
          this.success = data;
          setTimeout(() => this.success = null, 6000);
          this.loadingform = false;
          this.getTableData();
          setTimeout(function () {
            $('#password_popup').modal('toggle');
          }, 2000);
        },
        error => {
          this.errorForm = error;
          this.loadingform = false;
        });
  }
  onReset() {
    this.submitted = false;
    this.studentPasswordChangeForm.reset();
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  refreshList(e) {
    this.getTableData();
  }
  refreshListFromEditStudent(e) {
    this.getTableData();
    $('#popup_edit').modal('toggle');
  }
  edit(row) {
    this.editRow = row;
    $('#popup_edit').modal('toggle');
  }
  delete(confirmPopup, row) {
    this.deleteRow = row;
    this._modalService.open(confirmPopup, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;  
      if (result === 'yes') {
        this.studentDelete(row.id);
      }
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;  
    });

  }
  showNotes(popup, row) {
    this.child = row;
    this._modalService.open(popup, { centered: true, scrollable: true, windowClass: 'notes-popup' })
      .result.finally(() => this.child = null);
  }
  studentDelete(id) {
    this.sharedService.delete('teacher/delete-student/' + id + '/' + this.teacherId)
      .pipe(first())
      .subscribe(
        res => {
          console.log(res);
          this.getTableData();
        },
        error => {
          this.error = error;

        });
  }
}
