import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-account-verified',
  templateUrl: './account-verified.component.html',
  styleUrls: ['./account-verified.component.css']
})
export class AccountVerifiedComponent implements OnInit {

  link: string;
  error = '';
  loading = false;
  data: any;
  teacher_id: number
  email: string
  timezoneIdentifier = null;
  timezoneAbbreviation = null;
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private authenticationService: AuthenticationService
  ) {
    this.route.params.subscribe(params => {
      this.link = params.id;
    });
  }

  ngOnInit() {
    this.verify();
    this.timezoneIdentifier = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezoneAbbreviation = new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
  }

  verify() {
    this.sharedService.create('teacher/verify_email', { id: this.link })
      .pipe(first())
      .subscribe(
        data => {
          this.data = data;
          this.teacher_id = this.data.teacher.id;
          this.email = this.data.teacher.email;
          this.autoLogin();
          this.loading = true;
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  autoLogin() {
    this.authenticationService.teacherAutoLogin(this.teacher_id,this.email, this.timezoneIdentifier, this.timezoneAbbreviation)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigateByUrl('/?verified=1');
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
}
