import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UploadFilesService } from 'src/app/_services/upload-files.service';
import { SharedService } from 'src/app/_services/shared.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
  @Input() teacherId: number;
  @Input() postUrl: string;
  @Input() now: any;
  @Output() refreshList = new EventEmitter();
  selectedFiles?: FileList;
  currentFile?: File;
  progress = 0;
  message = '';
  fileName = 'upload file';
  errorMessage: any;
  fileTypeError = '';
  duplicateEntry = 0;
  inCompleteEntry = 0;
  emptyEntry = 0;
  newEntry = 0;
  emailFail = 0;

  fileInfos?: Observable<any>;
  @ViewChild('formFile', { static: true }) private formFile: ElementRef<HTMLInputElement>;

  constructor(private uploadService: UploadFilesService, private sharedService: SharedService) { }

  ngOnInit(): void {
    this.fileInfos = null;
    this.fileName = null;
    this.message = null;
    this.fileTypeError = null;
    // this.fileInfos = this.uploadService.getFiles();
  }

  ngOnChanges() {
    // console.log('i m calling');
    // console.log(this.now);
    this.fileInfos = null;
    this.message = null;
    this.fileName = 'upload file';
    this.fileTypeError = null;    
    this.errorMessage = null;
  }

  selectFile(event: any): void {
    this.fileInfos = null;
    this.message = null;
    this.fileTypeError = null
    this.selectedFiles = event.target.files;
    const file: File | null = this.selectedFiles.item(0);
    console.log(file);
    this.fileName = file.name;
    //check file is valid
    if (!this.validateFile(file.name)) {
      this.fileTypeError = 'Please upload a CSV file only';
      // this.fileName = 'upload file';
      return;
    }
  }

  clearFiles() {
    if (this.formFile && this.formFile.nativeElement) {
      this.formFile.nativeElement.value = null;
    }
  }

  upload(event: any): void {
    this.progress = 0;
    this.message = '';
    this.errorMessage = '';
    this.fileTypeError = '';
    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);
      if (file) {
        this.currentFile = file;
        this.uploadService.upload(this.currentFile, this.postUrl, { 'teacherId': this.teacherId }).subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              //this.fileInfos = event.body.data;
              if (event.body.data) {
                this.fileInfos = event.body.data.map(function (el) {
                  var o = Object.assign({}, el);
                  o.loading = false;
                  o.successMsg = {};
                  o.errorMsg = {};
                  return o;
                })
                this.fileName = 'upload file';
              }
              this.duplicateEntry = event.body.duplicate_entry;
              this.newEntry = event.body.new_entry;
              this.emailFail = event.body.email_fail;
              this.inCompleteEntry = event.body.incomplete_entry;
              this.emptyEntry = event.body.empty_entry;
              if (this.inCompleteEntry > 0) {
                this.message = "Your CSV file has been uploaded with errors";
              } else if (this.duplicateEntry > 0) {
                this.message = "Your CSV file has been uploaded with warnings";
              } else {
                this.message = event.body.message;
              }
              this.currentFile = undefined;
              // this.fileInfos = this.uploadService.getFiles();
              //refresh student list 
              if (this.newEntry > 0) {
                this.refreshList.emit();
              }
            }
            this.clearFiles();
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;
            /*if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'File could not be uploaded! Please try again';
            }*/
            if (err.hasOwnProperty('extension')) {
              console.log(err.extension);
              this.errorMessage = err.extension
            } else {
              this.errorMessage = err;
            }
            this.currentFile = undefined;
            this.clearFiles();
          });
      }
      this.selectedFiles = undefined;
    }
  }

  retryEmailSend(row) {
    row.loading = true;
    row.errorMsg = {};
    row.successMsg = {};
    // stop here if form is invalid
    this.sharedService.create('teacher/send-passcode', { id: row.id, password: row.password, teacher_id: row.teacher_id })
      .pipe(first())
      .subscribe(
        data => {
          row.loading = false;
          row.successMsg = data;
        },
        error => {
          row.errorMsg = error;
          row.loading = false;
        });
  }
  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'csv') {
      return true;
    }
    else {
      return false;
    }
  }
}
