import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor() { }

  private messageSource = new BehaviorSubject<any>(null);
  public message = this.messageSource.asObservable();

  getFirebaseAuthenticated() {
    return this.messageSource;
  }

  public setMessage(value: any) {
    this.messageSource.next(value);
  }
}
