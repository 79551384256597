import { Component, Input, OnInit } from '@angular/core';
import { throwError } from 'rxjs';
import { first } from 'rxjs/operators';
import { HelperService } from 'src/app/_helper/helper.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'tab-pronunciation',
  templateUrl: './pronunciation.component.html',
  styleUrls: ['./pronunciation.component.css']
})
export class PronunciationComponent implements OnInit {

  @Input() teacherId: number;
  @Input() childId: number;
  contentLoading: boolean = false;
  error: boolean = false;
  data: any;
  page: any;
  loadingMore: boolean = false;
  private totalPages = -1;

  constructor(
    private sharedService: SharedService,
    private helper: HelperService,
  ) {
    this.page = {
      "pageNo": 1,
      "pageSize": 10
    };
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/mispronounced-words', this.page)
      .pipe(first())
      .subscribe(
        res => {
          this.data = this.byDate(res.data.mispronounced_answers);
          this.totalPages = res.data.total_pages;
          console.log(this.data);
          this.contentLoading = false;
        },
        error => {
          this.error = error;
          this.contentLoading = false;
        });
  }

  color(index) {
    switch (index) {
      case 0:
        return "yellow-color";
        break;
      case 1:
        return "blue-color";
        break;
      case 2:
        return "pink-color";
        break;
      case 3:
        return "red-color";
        break;
      case 4:
        return "purple-color";
        break;
    }
  }

  // splitArr(arr, size) {
  //   let newArr = [];
  //   for (let i = 0; i < arr.length; i += size) {
  //     newArr.push(arr.slice(i, i + size));
  //   }
  //   console.log(newArr)
  //   return newArr;
  // }


  byDate(data) {
    // this gives an object with dates as keys
    const groups = data.reduce((groups, words) => {
      const date = words.created_at.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(words);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      //remove duplicate items
      var groupData = groups[date].reduce((acc, val) => {
        if (!acc.find(el => el.description === val.description)) {
          acc.push(val);
        }
        return acc;
      }, [])

      return {
        date,
        words: groupData
      };
    });

    return groupArrays;
  }
  onScroll(e) {
    console.log(e);
    if (this.page.pageNo >= this.totalPages) {
      console.log('No more items');
      return;
    }
    if (this.loadingMore) return;
    this.loadingMore = true;
    this.page.pageNo = this.page.pageNo + 1;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/mispronounced-words', this.page)
      .pipe(first())
      .subscribe(
        res => {
          const oldCount = this.data.length;
          this.data.push(...this.byDate(res.data.mispronounced_answers));
          for (let i = this.data.length - 1; i > 0; --i) {
            const current = this.data[i];
            const previous = this.data[i - 1];
            if (current.date == previous.date) {
              current.words.forEach(word => {
                if (previous.words.every(w => w && word && w.description != word.description)) {
                  previous.words.push(word);
                }
              });
              this.data.splice(i, 1);
            }
          }
          // this.splitArr(data, 5).map(item => {
          //   this.data.push(item);
          // });
          this.loadingMore = false;
          if (oldCount == this.data.length) {
            // current page had all duplicated data, need new page to update data
            this.onScroll(e);
          }
        },
        error => {
          this.error = error;
          // this.loadingMore = false;
        });
  }

  clickToTop() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('process-card-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  playAudio(file) {
    let audio = new Audio();
    audio.src = file;
    audio.load();
    audio.play();
  }
}
