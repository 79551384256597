import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CustomValidatorsService } from 'src/app/_helper/custom-validators.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.css']
})
export class AddStudentComponent implements OnInit {

  salutationList: any = ['Mr', 'Mrs', 'Miss']
  studentForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private customValidators: CustomValidatorsService,
    private authenticationService: AuthenticationService
  ) {


  }

  ngOnInit() {
    this.studentForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      parent_email: ['', [Validators.required, Validators.email, Validators.maxLength(190)]],
      notes: ['', [Validators.maxLength(1000)]]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.studentForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.studentForm.invalid) {
      return;
    }

    this.loading = true;
    this.studentForm.value.teacher_id = this.authenticationService.currentTeacherValue.id;
    this.sharedService.create('teacher/add-student', this.studentForm.value)
      .pipe(first())
      .subscribe(
        data => {
          const existingCount = Number(localStorage.getItem('student_count')) || 0;
          localStorage.setItem('student_count', (existingCount + 1).toString());
          this.success = data;
          setTimeout(() => this.success = null, 6000);
          this.loading = false;
          this.onReset();
          // this.router.navigate(['student-list']);  
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
  onReset() {
    this.submitted = false;
    this.studentForm.reset();
  }

}
