import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from '../app/app-routing.module'; // CLI imports AppRoutingModule
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_interceptors/jwt.interceptor';
import { ErrorInterceptor } from './_interceptors/error.interceptor';
import { fakeBackendProvider } from './_interceptors/fake-backend';
import { ParentUsersComponent } from './admin/parent-users/parent-users.component';
import { NotificationsComponent } from './admin/notifications/notifications.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { KiddictionComponent } from './admin/kiddiction/kiddiction.component';
import { QuestionsComponent } from './admin/questions/questions.component';
import { TrComponent } from './admin/tr/tr.component';
import { TeacherComponent } from './teacher/teacher.component';
import { TeacherLoginComponent } from './teacher/teacher-login/teacher-login.component';
import { FaqComponent } from './front/faq/faq.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FrontComponent } from './front/front.component';
import { PrivacyPolicyComponent } from './front/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './front/terms-of-use/terms-of-use.component';
import { DashboardComponent } from './teacher/dashboard/dashboard.component';
import { RegisterComponent } from './teacher/register/register.component';
import { ForgotPasswordComponent } from './teacher/forgot-password/forgot-password.component';
import { AddStudentComponent } from './teacher/add-student/add-student.component';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { StudentListComponent } from './teacher/student-list/student-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { RegistrationSuccessComponent } from './teacher/registration-success/registration-success.component';
import { AccountVerifiedComponent } from './teacher/account-verified/account-verified.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StudentDetailComponent } from './teacher/student-detail/student-detail.component';
import { EditProfileComponent } from './teacher/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './teacher/change-password/change-password.component';
import { SummaryComponent } from './teacher/student-detail/summary/summary.component';
import { FavoritesComponent } from './teacher/student-detail/favorites/favorites.component';
import { AnswersComponent } from './teacher/student-detail/answers/answers.component';
import { PronunciationComponent } from './teacher/student-detail/pronunciation/pronunciation.component';
import { UploadFilesComponent } from './shared/upload-files/upload-files.component';
import { MobileComponent } from './front/mobile/mobile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ComingSoonComponent } from './front/coming-soon/coming-soon.component';
import { IndexComponent } from './front/index/index.component';
import { TeacherHeaderComponent } from './teacher/teacher-header/teacher-header.component';
import { TeacherFooterComponent } from './teacher/teacher-footer/teacher-footer.component';
import { IndexTeacherComponent } from './teacher/index/index.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { EditStudentComponent } from './teacher/edit-student/edit-student.component';
import { GooglePlaceDirective } from './_directive/google-place.directive';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    ParentUsersComponent,
    NotificationsComponent,
    ReportsComponent,
    KiddictionComponent,
    QuestionsComponent,
    TrComponent,
    TeacherComponent,
    TeacherLoginComponent,
    FaqComponent,
    HeaderComponent,
    FooterComponent,
    FrontComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    DashboardComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    AddStudentComponent,
    StudentListComponent,
    RegistrationSuccessComponent,
    AccountVerifiedComponent,
    StudentDetailComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    SummaryComponent,
    FavoritesComponent,
    AnswersComponent,
    PronunciationComponent,
    UploadFilesComponent,
    MobileComponent,
    ComingSoonComponent,
    IndexComponent,
    TeacherHeaderComponent,
    TeacherFooterComponent,
    IndexTeacherComponent,
    EditStudentComponent,
    GooglePlaceDirective
  ],
  imports: [
    BrowserModule,
    ImageCropperModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    GooglePlaceModule,
    AutocompleteLibModule,
    NgxDatatableModule,
    NgbModule,    
    SlickCarouselModule,
    InfiniteScrollModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
