import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../_models/user';
import { Teacher } from '../_models/teacher';
import { TeacherService } from './teacher.service';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentUserTokenSubject: BehaviorSubject<User>;
  public currentUserToken: Observable<User>;

  private currentTeacherSubject: BehaviorSubject<User>;
  public currentTeacher: Observable<User>;

  private currentTeacherTokenSubject: BehaviorSubject<User>;
  public currentTeacherToken: Observable<User>;

  constructor(private http: HttpClient, private messengerService: TeacherService,) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentUserTokenSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUserToken')));
    this.currentUserToken = this.currentUserSubject.asObservable();

    this.currentTeacherSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentTeacher')));
    this.currentTeacher = this.currentTeacherSubject.asObservable();

    this.currentTeacherTokenSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentTeacherToken')));
    this.currentTeacherToken = this.currentTeacherTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentUserTokenValue(): User {
    return this.currentUserTokenSubject.value;
  }

  public get currentTeacherValue(): Teacher {
    return this.currentTeacherSubject.value;
  }

  public get currentTeacherTokenValue(): Teacher {
    return this.currentTeacherTokenSubject.value;
  }
  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiURL}/authenticate`, { email, password })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // user.user.token = user.token;
          localStorage.setItem('currentUser', JSON.stringify(user.user));
          this.currentUserSubject.next(user.user);
          localStorage.setItem('currentUserToken', JSON.stringify(user.token));
          this.currentUserSubject.next(user.token);
        }

        return user;
      }));
  }

  teacherLogin(email: string, password: string, timezoneIdentifier: string, timezoneAbbreviation: string) {
    return this.http.post<any>(`${environment.apiURL}/teacher-authenticate`, { email, password, timezoneIdentifier, timezoneAbbreviation })
      .pipe(map(teacher => {
        // login successful if there's a jwt token in the response
        if (teacher && teacher.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // teacher.teacher.token = teacher.token;
          localStorage.setItem('currentTeacher', JSON.stringify(teacher.teacher));
          localStorage.setItem('currentTeacherToken', JSON.stringify(teacher.token));
          this.messengerService.setMessage(teacher.teacher);
          this.currentTeacherSubject.next(teacher.teacher);
          this.currentTeacherTokenSubject.next(teacher.token);
        }
        return teacher;
      }));
  }
  teacherAutoLogin(id: number, email: string, timezoneIdentifier: string, timezoneAbbreviation: string) {
    return this.http.post<any>(`${environment.apiURL}/teacher-auto-authenticate`, { id, email, timezoneIdentifier, timezoneAbbreviation })
      .pipe(map(teacher => {
        // login successful if there's a jwt token in the response
        if (teacher && teacher.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // teacher.teacher.token = teacher.token;
          localStorage.setItem('currentTeacher', JSON.stringify(teacher.teacher));
          localStorage.setItem('currentTeacherToken', JSON.stringify(teacher.token));
          this.messengerService.setMessage(teacher.teacher);
          this.currentTeacherSubject.next(teacher.teacher);
          this.currentTeacherTokenSubject.next(teacher.token);
        }
        return teacher;
      }));
  }
  teacherLogout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentTeacher');
    localStorage.removeItem('currentTeacherToken');
    localStorage.removeItem('student_count');
    this.currentTeacherSubject.next(null);
    this.currentTeacherTokenSubject.next(null);
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserToken');
    this.currentUserSubject.next(null);
    this.currentUserTokenSubject.next(null);
  }
}
