import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { SharedService } from 'src/app/_services/shared.service';
declare var $;

@Component({
  selector: 'tab-answers',
  templateUrl: './answers.component.html',
  styleUrls: ['./answers.component.css']
})
export class AnswersComponent implements OnInit {

  @Input() teacherId: number;
  @Input() childId: number;
  contentLoading: boolean = false;
  error: boolean = false;
  data: any;
  page: any;
  total_count: any;
  loadingMore: boolean = false;

  filterList: any = [{ "name": "All", "value": "all" }, { "name": "Correct Answers", "value": "correct" }, { "name": "Incorrect Answers", "value": "incorrect" }];
  constructor(
    private sharedService: SharedService,
  ) {
    this.page = {
      "pageNo": 1,
      "pageSize": 20,
      "filter": 'all'
    };
  }

  ngOnInit() {
    this.getData();
  }

  updateFilter(selected) {
    this.page.pageNo = 1;
    this.page.filter = selected;
    this.getData();
  }
  getData() {
    this.contentLoading = true;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/all-questions', this.page)
      .pipe(first())
      .subscribe(
        res => {
          this.data = this.byDate(res.data.questions);
          this.total_count = res.data.total_count;
          this.contentLoading = false;
        },
        error => {
          this.error = error;
          this.contentLoading = false;
        });
  }

  openTypesOfQuestionsPopup() {
    $('#types-of-questions-popup').modal('show');
  }

  byDate(data) {
    // this gives an object with dates as keys
    const groups = data.reduce((groups, answers) => {
      const date = answers.answered_date.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(answers);
      return groups;
    }, {});

    // Edit: to add it in the array format instead
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        answers: groups[date]
      };
    });

    return groupArrays;
  }
  onScroll(e) {
    if (this.data.length == this.total_count) {
      console.log('No more items');
      return;
    }
    this.loadingMore = true;
    this.page.pageNo = this.page.pageNo + 1;
    this.sharedService.getRawData('teacher/child-analytics/' + this.childId + '/all-questions', this.page)
      .pipe(first())
      .subscribe(
        res => {
          res.data.questions = this.byDate(res.data.questions);
          res.data.questions.map(item => {
            this.data.push(item);
          });
          console.log(this.data);
          this.loadingMore = false;
        },
        error => {
          this.error = error;
          // this.loadingMore = false;
        });
  }

  clickToTop() {
    // Hack: Scrolls to top of Page after page view initialized
    let top = document.getElementById('process-card-top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }
}
