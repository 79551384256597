import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { CustomValidatorsService } from 'src/app/_helper/custom-validators.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { SharedService } from 'src/app/_services/shared.service';

@Component({
  selector: 'edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.css']
})
export class EditStudentComponent implements OnInit {
  @Input() studentData: any;
  @Input()
  set setSomeInputValue(val) {
    this.studentData = val;
  }

  @Output() refreshListFromEditStudent = new EventEmitter();
  salutationList: any = ['Mr', 'Mrs', 'Miss']
  studentForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  success = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private customValidators: CustomValidatorsService,
    private authenticationService: AuthenticationService
  ) {


  }

  ngOnInit() {
    this.createForm();
  }
  ngOnChanges() {
    this.success = null;
    this.error = null;
    this.loading = null;
    this.submitted = null;
    this.createForm();
    if (this.studentData) {
      console.log(this.studentData)
      this.studentForm.patchValue({
        'firstName': this.studentData.first_name,
        'lastName': this.studentData.last_name,
        'parent_email': this.studentData.parent_email,
        'notes': this.studentData.notes
      });
    }
  }
  createForm() {
    this.studentForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      lastName: ['', [Validators.required, Validators.maxLength(190), this.customValidators.nameValidator]],
      parent_email: ['', [Validators.required, Validators.email, Validators.maxLength(190)]],
      notes: ['', [Validators.maxLength(1000)]]
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.studentForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.studentForm.invalid) {
      return;
    }
    this.loading = true;
    this.studentForm.value.id = this.studentData.id;
    this.studentForm.value.teacher_id = this.authenticationService.currentTeacherValue.id;
    this.sharedService.update('teacher/edit-student', this.studentForm.value)
      .pipe(first())
      .subscribe(
        data => {
          this.loading = false;
          console.log(data);
          if (data.status) {
            this.success = data.message;
            setTimeout(() => this.success = null, 6000);
          }
          this.refreshListFromEditStudent.emit();
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }
  onReset() {
    this.submitted = false;
    this.studentForm.reset();
  }

}
