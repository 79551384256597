import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kiddiction',
  templateUrl: './kiddiction.component.html',
  styleUrls: ['./kiddiction.component.css']
})
export class KiddictionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
